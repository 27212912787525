<template>
  <div class="f-loading" v-if="loading">
    <van-loading vertical>加载中...</van-loading>
  </div>
  <div class="f-home" v-else>
    <van-cell-group v-for="group in groups" :key="group.id" :title="group.title">
      <van-cell v-for="template in group.templates" :key="template.id"
        :icon="template.icon === '' ? 'photo-o' : template.icon + '?x-oss-process=image/resize,m_fill,h_150,w_150'"
        :to="`/appl-form?templateId=${template.id}`" is-link>
        <template #title>
          {{ template.title }}
          <van-badge v-if="hasDraft(template.id)" dot />
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import axios from 'axios';
import { Loading, Cell, CellGroup, Tabbar, TabbarItem, Badge } from 'vant';
import { handleError } from '../utils';

export default {
  data() {
    return {
      loading: true,
      groups: [],
      drafts: store.get('drafts'),
    };
  },
  created() {
    axios.get('/api/template-groups')
      .then(response => {
        this.groups = _.get(response, ['data', 'templateGroups'], []);
      })
      .catch(error => {
        handleError(error);
      })
      .then(() => {
        this.loading = false;
      });
  },
  methods: {
    hasDraft(templateId) {
      return _.has(this.drafts, `template_${templateId}`);
    },
  },
  components: {
    [Loading.name]: Loading,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Badge.name]: Badge,
  },
};
</script>

<style scoped>
.f-loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
