<template>
  <div class="f-uploader">
    <div v-for="(item, index) in value" v-bind:key="index" :class="{ item: true, failed: item.status === 'failed' }">
      <div class="van-ellipsis" style="max-width: 48vw; margin: 4px 0; cursor: pointer; color: #1989fa"
        @click="previewFile(item)">
        {{ item.name }}
      </div>
      <div class="upload" v-if="editable">
        <van-icon name="upgrade" class="patch" size="20px" color="#1989fa" />
        <input type="file" v-on:change="onChange($event, index)" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { Loading, Icon, Toast } from "vant";
import { handleError, isWxworkMobile } from "../../utils";

export default {
  name: "f-uploader",
  computed: {
    editable() {
      const editableNodeUUIDs = this.editableNodeUUIDs
        ? this.editableNodeUUIDs
        : [];
      const currentNode = this.appl.nodes.find(
        (node) => node.status === "pending"
      );
      let editable = currentNode
        ? editableNodeUUIDs.includes(currentNode.uuid)
        : false;
      return editable
        ? !!currentNode.results.find((result) => result.userId === this.user.id)
        : false;
    },
  },
  methods: {
    onChange(event, index) {
      const items = [...this.value];
      Array.from(event.target.files).forEach((file) => {
        items[index] = {
          status: "uploading",
          name: file.name,
          size: file.size,
          url: "",
        };
        this.uploadFile(file, items, index);
      });
      this.$emit("input", items);
    },
    uploadFile(file, items, index) {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "上传中...",
      });
      items[index].status = "uploading";
      const data = new FormData();
      data.append("file", file);
      axios
        .post("/api/misc/upload-file", data)
        .then((response) => {
          const {
            data: { size, url },
          } = response;
          items[index].status = "done";
          items[index].size = size;
          items[index].url = url;

          return axios.patch(`/api/appls/${this.appl.id}`, {
            uuid: this.uuid,
            value: items,
          });
        })
        .then((response) => {
          Toast.success(response.data.message);
        })
        .catch((error) => {
          handleError(error);
          items[index].status = "failed";
          toast.clear();
        });
    },
    previewFile({ name, size, url }) {
      const protocol = window.location.protocol;
      if (isWxworkMobile()) {
        // eslint-disable-next-line
        wx.previewFile({
          name,
          size,
          url: protocol + url,
        });
        return;
      }
      window.open(`/api/misc/preview-file?url=${encodeURIComponent(protocol+url)}&name=${encodeURIComponent(name)}`);
    },
  },
  props: {
    appl: Object,
    user: Object,
    uuid: String,
    value: Array,
    editableNodeUUIDs: Array,
  },
  components: {
    [Loading.name]: Loading,
    [Icon.name]: Icon,
  },
};
</script>

<style scoped>
.f-uploader {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.f-uploader .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ebedf0;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 8px;
}

.f-uploader .item.failed {
  color: #ee0a24;
  border: 1px solid #ee0a24;
}

.f-uploader .item .patch {
  cursor: pointer;
}

.f-uploader .upload {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
}

.f-uploader .upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  text-indent: -4096px;
}
</style>
>
